.card.wms-event-card {
  border-left-width: 10px;
  border-left-style: solid;
  margin-bottom: 15px;

  & .card-body {
    padding: 0.75rem 1.25rem;
  }

  & .card-title {
    font-size: inherit;
    color: var(--purple);

    & .btn-link:hover {
      background-color: var(--light);
    }
  }

  & .card-subtitle {
    font-size: 18px;
  }

  & .amount {
    font-size: 16px;
    font-weight: bold;
  }
}

@each $color in var(--bs-color-label-arr) {
  .card.wms-event-card-$(color) {
    border-left-color: var(--$(color)-color);
  }
}