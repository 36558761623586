@import './fount.css';

/* components */
@import './EventCard.css';

/* Recharts */
.recharts-responsive-container {
  margin: auto;
}

/* Custom */
.stock-view .table > tbody > tr > td,
.stock-view .table > tbody > tr > th {
  padding: 2px;
}

.stock-view .table > thead > tr > td {
  padding: 0.2rem;
}

.icon-button {
  cursor: pointer;
  padding: 2px;
  border: 1px solid white;
  margin-left: 5px;
  width: 18px;
  height: 18px;
}
